// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCa8Sg7wRudoA_eZfzSH4MpfK7SzCtgxGI",
    authDomain: "dei-p2tl.firebaseapp.com",
    projectId: "dei-p2tl",
    storageBucket: "dei-p2tl.appspot.com",
    messagingSenderId: "184530137327",
    appId: "1:184530137327:web:5d2b2b322ebdd68fbd2b1a",
    measurementId: "G-4GD8KRJ8GR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app)
const analytics = getAnalytics(app);

export { messaging }