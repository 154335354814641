import React from 'react'
import { prediksi_temuan, golongan_pelanggaran } from "../../constants/dummyData"

const Page3 = ({ state, setState, handleChange, handleSave }) => {
    return (
        <>
            <div className="form_group_p2tl">
                <label htmlFor="prediksi_temuan">Prediksi Temuan</label>
                <div className="flex relative items-center">
                    <select
                        id="prediksi_temuan"
                        className="form_input_p2tl"
                        onChange={handleChange}
                        value={state?.prediksi_temuan}
                    >
                        {prediksi_temuan.map((e) => (
                            <option value={e.name} key={e.id}>
                                {e.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {state?.prediksi_temuan == "Temuan" ? (
                <>
                    <div className="form_group_p2tl">
                        <label htmlFor="golongan_pelanggaran">
                            Golongan Pelanggaran
                        </label>
                        <div className="flex relative items-center">
                            <select
                                id="golongan_pelanggaran"
                                className="form_input_p2tl"
                                onChange={handleChange}
                                value={
                                    state?.jenis_kwh_meter == "PJU" ||
                                        state?.jenis_kwh_meter == "Non Pelanggan"
                                        ? "P4"
                                        : state?.golongan_pelanggaran
                                }

                            >
                                {state?.jenis_kwh_meter == "PJU" ||
                                    state?.jenis_kwh_meter == "Non Pelanggan" ? (
                                    <option value="P4">P4</option>
                                ) : (
                                    <>
                                        {golongan_pelanggaran.map((e) => (
                                            <option value={e.name} key={e.id}>
                                                {e.name}
                                            </option>
                                        ))}
                                    </>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="form_group_p2tl">
                        <label htmlFor="kesimpulan">Kesimpulan</label>
                        <textarea
                            className="form_textarea_p2tl"
                            id="kesimpulan"
                            onChange={handleChange}
                            value={state?.kesimpulan}
                        ></textarea>
                    </div>
                </>
            ) : (
                <>
                    <button
                        className="h-12 bg-blue-600 text-white rounded-lg"
                        onClick={handleSave}
                    >
                        Selesai
                    </button>
                </>
            )}

            {state.prediksi_temuan == "Temuan" && (
                <div className="w-full flex justify-between gap-2">
                    <button className="h-10 bg-gray-400 text-white w-[80px] rounded-lg"
                        onClick={() => {
                            setState((prev) => (prev = { ...prev, page: state.page - 1 }));
                        }}
                    >Back</button>
                    <button className="h-10 bg-blue-600 text-white w-[80px] rounded-lg"
                        onClick={() => {
                            setState((prev) => (prev = { ...prev, page: state.page + 1 }));
                        }}
                    >Next</button>
                </div>
            )}

        </>
    )
}

export default Page3