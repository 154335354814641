import React from 'react'
import { Outlet } from "react-router-dom"
import ButtonNavigation from '../components/ButtonNavigation'
import { getToken } from "firebase/messaging"
import { messaging } from "../firebase"

const Dashboard = () => {

    // Notification.requestPermission().then((permission) => {
    //     if (permission === 'granted') {
    //         console.log('Notification permission granted.');
    //         getToken(messaging).then((token) => {
    //             console.info(token)
    //             localStorage.setItem("fcm_token", token)
    //         })
    //     } else {
    //         console.warn('Notification permission denied.');
    //     }
    // })

    return (
        <div className='container_mobile'>
            <Outlet />
        </div>
    )
}

export default Dashboard