import React from 'react'
import { lokasi_simpan } from "../../constants/dummyData"

const Page5 = ({ state, setState, handleChange, fotoBarangBukti, handleShowModalImage, handleFotoBarangBukti }) => {
    return (
        <>
            <div className="form_group_p2tl">
                <label htmlFor="no_ba">Nomer Barang Bukti</label>
                <input
                    type="text"
                    className="form_input_p2tl"
                    id="no_ba"
                    onChange={handleChange}
                    value={state?.no_ba}
                />
            </div>

            <div className="form_group_p2tl">
                <label htmlFor="lokasi_simpan">Lokasi Simpan</label>
                <select
                    id="lokasi_simpan"
                    className="form_input_p2tl"
                    onChange={handleChange}
                    value={state?.lokasi_simpan}
                >
                    {lokasi_simpan?.map((e) => (
                        <option value={e.name} key={e.id}>
                            {e.name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="form_group_p2tl">
                <label
                    htmlFor="foto_barang_bukti"
                    className="h-12 w-full bg-blue-600 text-white rounded-lg flex justify-center items-center"
                >
                    Foto Barang Bukti
                </label>
                <input
                    type="file"

                    className="hidden"
                    id="foto_barang_bukti"
                    onChange={handleFotoBarangBukti}
                    accept="image/*"
                />
            </div>

            <div className="grid grid-cols-4 gap-4 w-full h-auto">
                {fotoBarangBukti.map((e, i) => {
                    return (
                        <div onClick={() => {
                            handleShowModalImage(e, i, "fotoBarangBukti")
                        }} key={i}>
                            <img src={e} alt="" className="w-[100%] h-20 object-cover" />
                        </div>
                    );
                })}
            </div>

            <div className="w-full flex justify-between gap-2">
                <button className="h-10 bg-gray-400 text-white w-[80px] rounded-lg"
                    onClick={() => {
                        setState((prev) => (prev = { ...prev, page: state.page - 1 }));
                    }}
                >Back</button>
                <button className="h-10 bg-blue-600 text-white w-[80px] rounded-lg"
                    onClick={() => {
                        setState((prev) => (prev = { ...prev, page: state.page + 1 }));
                    }}
                >Next</button>
            </div>
        </>
    )
}

export default Page5