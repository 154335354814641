import axios from "axios";
import moment from "moment";

const create_p2tl = async (idpel) => {
    try {

        let defaultLocalData = await JSON.parse(localStorage.getItem('dei_temp_data'))

        let state = await defaultLocalData.filter((e) => {
            return e.idpel == idpel
        })[0]

        let sisa = await defaultLocalData.filter((e) => {
            return e.idpel != idpel
        })

        const data = await {
            ...state,
            nominal_dp: parseInt(state.nominal_dp),
            tanggal_ba: moment().format('DD/MM/YYYY hh:mm:ss'),
            foto_ba: state.foto_ba.length > 0 ? JSON.stringify(`${state.foto_ba}`) : "\"\"",
            foto_pemeriksaan: state.foto_pemeriksaan.length > 0 ? JSON.stringify(`${state.foto_pemeriksaan}`) : "\"\"",
            foto_barang_bukti: state.foto_barang_bukti.length > 0 ? JSON.stringify(`${state.foto_barang_bukti}`) : "\"\""
        }

        const token = await localStorage.getItem('_dei_token')
        const result = await axios.post(process.env.REACT_APP_API_HOST + `/dhp/create`, {
            nomer_ba: data.nomer_ba,
            nama_petugas: data.nama_petugas,
            jenis_kwh_meter: data.jenis_kwh_meter,
            id_pln: data.idpel,
            tanggal_ba: data.tanggal_ba,
            nomer_ktp: data.nomer_ktp,
            nama: data.nama,
            tarif: data.tarif,
            daya: data.daya,
            alamat: data.alamat,
            prediksi_temuan: data.prediksi_temuan,
            golongan_pelanggaran: data.golongan_pelanggaran,
            kesimpulan: data.kesimpulan,
            prediksi_kwh: data.prediksi_kwh,
            status_temuan: data.status_temuan,
            foto_ba: data.foto_ba,
            foto_pemeriksaan: data.foto_pemeriksaan,
            foto_barang_bukti: data.foto_barang_bukti,
            nomer_barang_bukti: data.nomer_barang_bukti,
            lokasi_simpan: data.lokasi_simpan,
            jenis_material: data.jenis_material,
            merk: data.merk,
            type: data.type,
            no_seri: data.no_seri,
            jumlah: data.jumlah,
            keterangan: data.keterangan,
            nominal_dp: data.nominal_dp,
            status: data.status,
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })

        if (result.status == 201) {
            localStorage.setItem('dei_temp_data', JSON.stringify(sisa))
        }

        return result
    } catch (error) {
        return error
    }
}


export const create_temp_p2tl = async (data) => {
    try {
        let defaultLocalData = await JSON.parse(localStorage.getItem('dei_temp_data'))
        if (defaultLocalData == undefined) {
            defaultLocalData = []
        }
        let pushToLocalData = await defaultLocalData.push(data)
        let storeBack = await localStorage.setItem('dei_temp_data', JSON.stringify(defaultLocalData))

        return true

    } catch (error) {
        return error
    }
}

export default create_p2tl