import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AiOutlineArrowUp, AiOutlineClose, AiOutlineDelete, AiOutlineSearch } from "react-icons/ai";
import Header from "../components/Header";
import {
  golongan_pelanggaran,
  jenis_kwh_meter,
  jenis_material,
  jenis_pelanggan,
  lokasi_simpan,
  prediksi_temuan,
  status_temuan,
} from "../constants/dummyData";
import { useNavigate } from "react-router-dom";
import { customer_search } from "../api/customer";
import Page1 from "./report/page_1";
import Page2 from "./report/page_2";
import Page3 from "./report/page_3";
import Page4 from "./report/page_4";
import Page5 from "./report/page_5";
import { uploader_create } from "../api/uploader";
import { useQuery } from "react-query";
import { user_auth } from "../api/user/auth";
import { create_p2tl } from "../api/p2tl";
import moment from "moment";
import { create_temp_p2tl } from "../api/p2tl/create";

const ReportAdd = () => {

  const { data: user_data, isLoading: user_isLoading, refetch: user_refetch } = useQuery({ queryKey: ['user'], queryFn: user_auth })

  const navigate = useNavigate();
  const [state, setState] = useState({
    page: 1,
    prediksi_temuan: "Temuan",
    jenis_kwh_meter: "Pasca Bayar",
    golongan_pelanggaran: "P1",
    status_temuan: "Extra",
    lokasi_simpan: "Kantor DEI",
    jenis_material: "Meter",
    nominal_dp: 0,
    foto_ba: [],
    foto_pemeriksaan: [],
    foto_barang_bukti: [],
    modalImage: {
      show: false,
      value: '',
      index: 0,
      type: "fotoBa"
    }
  });
  const [fotoBa, setFotoBa] = useState([]);
  const [fotoPemeriksaan, setFotoPemeriksaan] = useState([])
  const [fotoBarangBukti, setFotoBarangBukti] = useState([])

  const handleChange = (e) => {
    setState(
      (prev) =>
      (prev = {
        ...prev,
        [e.target.id]: e.target.value,
      })
    );
  };

  useEffect(() => {
    return () => {
      window.onbeforeunload = () =>
        "Data Pada form yang sudah diisi akan terdelete";
    };
  }, [state]);

  useEffect(() => { console.info(state) }, [state])

  const handleSave = () => {
    alert("Terimakasih Sudah Melakukan Input");
    // console.info(user_data)

    create_temp_p2tl({
      ...state,
      nominal_dp: parseInt(state.nominal_dp),
      tanggal_ba: moment().format('DD/MM/YYYY hh:mm:ss'),
    })
      .then(res => {
        navigate("/", { replace: true })
      })
      .catch(err => {
        alert("Terjadi kesalahan")
      })

  };

  const handleFotoBa = async (e) => {
    let lg = await e?.target?.files?.length;
    let reader = new FileReader();
    for (let index = 0; index <= lg; index++) {
      reader.onload = (x) => {
        uploader_create(x.target.result, user_data.data.query.id)
          .then(res => {
            if (!res.status) {
              console.info(res)
              return
            }
            setFotoBa((prev) => (prev = [...prev, res.data.query.url]));
            setState(prev => prev = { ...prev, foto_ba: [...state.foto_ba, res.data.query.url] })
          })
          .catch(err => {
            console.error(err)
          })
      };
      reader.readAsDataURL(e.target.files[index]);
    }
  };

  const handleFotoPemeriksaan = async (e) => {
    let lg = await e?.target?.files?.length;
    let reader = new FileReader();
    for (let index = 0; index <= lg; index++) {
      reader.onload = (x) => {
        uploader_create(x.target.result, user_data.data.query.id)
          .then(res => {
            if (!res.status) {
              console.info(res)
              return
            }
            setFotoPemeriksaan((prev) => (prev = [...prev, res.data.query.url]));
            setState(prev => prev = { ...prev, foto_pemeriksaan: [...state.foto_pemeriksaan, res.data.query.url] })

          })
          .catch(err => {
            console.error(err)
          })
      };
      reader.readAsDataURL(e.target.files[index]);
    }
  }

  const handleFotoBarangBukti = async (e) => {
    let lg = await e?.target?.files?.length;
    let reader = new FileReader();
    for (let index = 0; index <= lg; index++) {
      reader.onload = (x) => {
        uploader_create(x.target.result, user_data.data.query.id)
          .then(res => {
            if (!res.status) {
              console.info(res)
              return
            }
            setFotoBarangBukti((prev) => (prev = [...prev, res.data.query.url]));
            setState(prev => prev = { ...prev, foto_barang_bukti: [...state.foto_barang_bukti, res.data.query.url] })

          })
          .catch(err => {
            console.error(err)
          })
      };
      reader.readAsDataURL(e.target.files[index]);
    }
  }

  const handleShowModalImage = (e, i, t) => {
    setState(prev => prev = {
      ...prev, modalImage: {
        show: true,
        value: e,
        index: i,
        type: t
      }
    })
  }

  const handleDeleteImageBa = () => {
    let newArr = fotoBa
    let newArrFil = newArr.filter((x) => {
      return x != state.modalImage.value
    })
    let set1 = setState(prev => prev = {
      ...prev, modalImage: {
        show: false
      }
    })
    let set2 = setFotoBa(newArrFil)
  }

  const handleDeleteImagePemeriksaan = () => {
    let newArr = fotoPemeriksaan
    let newArrFil = newArr.filter((x) => {
      return x != state.modalImage.value
    })
    let set1 = setState(prev => prev = {
      ...prev, modalImage: {
        show: false
      }
    })
    let set2 = setFotoPemeriksaan(newArrFil)
  }


  const handleDeleteImageBarangBukti = () => {
    let newArr = fotoBarangBukti
    let newArrFil = newArr.filter((x) => {
      return x != state.modalImage.value
    })
    let set1 = setState(prev => prev = {
      ...prev, modalImage: {
        show: false
      }
    })
    let set2 = setFotoBarangBukti(newArrFil)
  }

  const handleSelectedIdPel = (data) => {
    setState(prev => prev = {
      ...prev,
      searchIdPel: [],
      idpel: data.IDPEL,
      nomer_ktp: data.NIK,
      nama: data.NAMA,
      tarif: data.TARIF,
      daya: data.DAYA,
      alamat: data.NAMAPNJ
    })
  }

  useEffect(() => {
    const controller = new AbortController()
    if (state.idpel == "" && state.searchIdPel == []) {
      return setState(prev => prev = { ...prev, searchIdPel: [] })
    }
    customer_search(state.idpel, controller.signal)
      .then(res => {
        setState(prev => prev = { ...prev, searchIdPel: res?.data?.query })
      })
      .catch(err => { console.error(err) })

    return () => {
      controller.abort()
    }
  }, [state.idpel])

  if (state.modalImage.show) {
    return (
      <div className="w-screen h-screen flex flex-col justify-center items-center fixed top-0 left-0 bg-black">
        <button className="bg-red-700 text-white w-12 h-12 flex flex-col justify-center items-center absolute top-2 right-16"
          onClick={() => {
            // state.modalImage.type == "fotoBa" ? handleDeleteImageBa : handleDeleteImagePemeriksaan
            if (state.modalImage.type == "fotoBa") {
              return handleDeleteImageBa()
            }
            if (state.modalImage.type == "fotoPemeriksaan") {
              return handleDeleteImagePemeriksaan()
            }

            return handleDeleteImageBarangBukti()

          }}
        ><AiOutlineDelete /></button>
        <button className="bg-black text-white w-12 h-12 flex flex-col justify-center items-center absolute top-2 right-2"
          onClick={() => {
            setState(prev => prev = {
              ...prev, modalImage: {
                show: false,
                value: ""
              }
            })
          }}
        ><AiOutlineClose /></button>
        <img src={state.modalImage.value} alt="" />
      </div>
    )
  }

  return (
    <>
      <Header
        title={"Tambahkan P2TL"}
        back={state.page == 1}
        next={state.page == 6}
        nextMethod={handleSave}
      />

      <div className="form_p2tl">
        {/* HALAMAN 1 */}
        {state.page == 1 && (
          <Page1 state={state} setState={setState} handleChange={handleChange} handleSelectedIdPel={handleSelectedIdPel} />
        )}
        {/* END HALAMAN 1 */}

        {/* HALAMAN 2 */}
        {state.page == 2 && (
          <Page2 state={state} setState={setState} handleChange={handleChange} />
        )}
        {/* END HALAMAN 2 */}

        {/* HALAMAN 3 */}
        {state.page == 3 && (
          <Page3 state={state} setState={setState} handleChange={handleChange} handleSave={handleSave} />
        )}
        {/* END HALAMAN 3 */}

        {/* HALAMAN 4 */}
        {state.page == 4 && (
          <Page4
            state={state}
            setState={setState}
            handleChange={handleChange}
            handleShowModalImage={handleShowModalImage}
            handleFotoBa={handleFotoBa}
            handleFotoPemeriksaan={handleFotoPemeriksaan}
            fotoBa={fotoBa}
            fotoPemeriksaan={fotoPemeriksaan}
          />
        )}
        {/* END HALAMAN 4 */}

        {/* HALAMAN 5 */}
        {state.page == 5 && (
          <Page5
            state={state}
            setState={setState}
            handleChange={handleChange}
            handleShowModalImage={handleShowModalImage}
            handleFotoBarangBukti={handleFotoBarangBukti}
            fotoBarangBukti={fotoBarangBukti}
          />
        )}
        {/* END HALAMAN 5 */}

        {/* HALAMAN 6 */}
        {state.page == 6 && (
          <>
            <h1 className="text-blue-600 text-xl">Material Bongkar</h1>

            <div className="form_group_p2tl">
              <label htmlFor="jenis_material">Jenis Material</label>
              <div className="flex relative items-center">
                <select id="jenis_material" className="form_input_p2tl" onChange={handleChange}>
                  {jenis_material.map((e) => (
                    <option value={e.name} key={e.id}>
                      {e.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form_group_p2tl">
              <label htmlFor="merk">Merk</label>
              <input
                type="text"
                className="form_input_p2tl"
                id="merk"
                onChange={handleChange}
                value={state?.merk}
              />
            </div>

            <div className="form_group_p2tl">
              <label htmlFor="type">Type</label>
              <input
                type="text"
                className="form_input_p2tl"
                id="type"
                onChange={handleChange}
                value={state?.type}
              />
            </div>

            <div className="form_group_p2tl">
              <label htmlFor="no_seri">No Seri</label>
              <input
                type="text"
                className="form_input_p2tl"
                id="no_seri"
                onChange={handleChange}
                value={state?.no_seri}
              />
            </div>

            <div className="form_group_p2tl">
              <label htmlFor="jumlah">Jumlah</label>
              <input
                type="text"
                className="form_input_p2tl"
                id="jumlah"
                onChange={handleChange}
                value={state?.jumlah}
              />
            </div>

            <div className="form_group_p2tl">
              <label htmlFor="keterangan">keterangan</label>
              <textarea
                className="form_textarea_p2tl"
                id="keterangan"
                onChange={handleChange}
                value={state?.keterangan}
              ></textarea>
            </div>


          </>
        )}
        {/* END HALAMAN 6 */}
      </div>

      {/* BUTTON BAWAH */}
      <div className="navigation_p2tl">
        <button
          className={
            state.page == 1
              ? `navigation_p2tl_item_active`
              : `navigation_p2tl_item_disable`
          }
          onClick={() => {
            setState((prev) => (prev = { ...prev, page: 1 }));
          }}
        >
          1
        </button>

        <button
          className={
            state.page == 2
              ? `navigation_p2tl_item_active`
              : `navigation_p2tl_item_disable`
          }
          onClick={() => {
            setState((prev) => (prev = { ...prev, page: 2 }));
          }}
        >
          2
        </button>

        <button
          className={
            state.page == 3
              ? `navigation_p2tl_item_active`
              : `navigation_p2tl_item_disable`
          }
          onClick={() => {
            setState((prev) => (prev = { ...prev, page: 3 }));
          }}
        >
          3
        </button>

        {state?.prediksi_temuan == "Temuan" && (
          <>
            <button
              className={
                state.page == 4
                  ? `navigation_p2tl_item_active`
                  : `navigation_p2tl_item_disable`
              }
              onClick={() => {
                setState((prev) => (prev = { ...prev, page: 4 }));
              }}
            >
              4
            </button>

            <button
              className={
                state.page == 5
                  ? `navigation_p2tl_item_active`
                  : `navigation_p2tl_item_disable`
              }
              onClick={() => {
                setState((prev) => (prev = { ...prev, page: 5 }));
              }}
            >
              5
            </button>

            <button
              className={
                state.page == 6
                  ? `navigation_p2tl_item_active`
                  : `navigation_p2tl_item_disable`
              }
              onClick={() => {
                setState((prev) => (prev = { ...prev, page: 6 }));
              }}
            >
              6
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default ReportAdd;
