import axios from "axios"
import { api_host } from "../../constants/config"

export const user_auth = async () => {
    try {
        const token = await localStorage.getItem("_dei_token")
        if (!token) {
            return false
        }
        const result = await axios.get(api_host + "/user/auth", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        return result
    } catch (error) {
        return error
    }
}