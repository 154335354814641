import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AiOutlineArrowUp, AiOutlineEdit, AiOutlineSearch, AiOutlineUser } from 'react-icons/ai'
import { isError, useQuery } from 'react-query'
import profile_auth from '../../api/profile/auth'
import ButtonNavigation from '../../components/ButtonNavigation'
import Header from '../../components/Header'
import LoadingScreen from '../../components/LoadingScreen'
import { useForm } from 'react-hook-form'
import uploader_create from '../../api/uploader/create'
import { profile_create } from '../../api/profile'
import { useNavigate } from 'react-router-dom'


const ProfileAdd = () => {
    const [state, setState] = useState({
        edit: false,
        photo_profile: null,
        isLoading: false
    })

    const { register, formState: { errors }, watch, handleSubmit } = useForm()
    const navigation = useNavigate()
    // handle upload
    const handlePhotoProfile = (e) => {
        setState(prev => prev = { ...prev, isLoading: true })
        const reader = new FileReader()
        reader.onload = (x) => {
            uploader_create(x.target.result, 'profile')
                .then(res => {
                    setState(prev => prev = {
                        ...prev,
                        photo_profile: res.data.query.url
                    })
                })
        }
        reader.readAsDataURL(e.target.files[0])
    }

    const handleCreateProfile = (e) => {
        if (!state.photo_profile) {
            return alert("Mohon masukan photo profile")
        }
        profile_create({
            nama_lengkap: e.nama_lengkap,
            email: e.email,
            phone: e.phone,
            photo: state.photo_profile,
        })
            .then(res => {
                navigation("/profiles")
            })
            .catch(err => {
                console.error(err)
            })
    }

    return (
        <>
            <div className=''>

                <Header title={"Edit Profile"} />

                {state.photo_profile ? (
                    <div className='w-[220px] mx-auto flex flex-col justify-center items-center mt-14 relative'>
                        <img src={state.photo_profile} alt="" className='w-[220px] h-[220px] object-cover rounded-full shadow-lg' />
                        <label htmlFor="photo" className='h-12 w-12 bg-yellow-400 text-black text-lg rounded-full flex justify-center items-center absolute
                         right-2 bottom-2'
                        >
                            <AiOutlineEdit />
                        </label>
                        <input type="file" id='photo' accept="image/*" hidden onChange={handlePhotoProfile} />
                    </div>
                ) : (
                    <div className='w-full flex flex-col justify-center items-center mt-14 '>
                        <label htmlFor="photo" className='flex flex-col gap-2 justify-center items-center text-gray-400 bg-white p-6 rounded-full 
                        w-[220px] h-[220px]'>
                            <AiOutlineUser className='text-8xl  ' />
                            <small className='text-sm'> Upload Foto Profile</small>
                        </label>
                        <input type="file" id='photo' accept="image/*" hidden onChange={handlePhotoProfile} disabled={state.isLoading} />
                    </div>
                )}

                <form className='flex flex-col w-full gap-4' onSubmit={handleSubmit(handleCreateProfile)}>

                    <div className='flex justify-center items-center flex-col'>
                        <img src="" alt="" className='rounded-full' />
                    </div>

                    <div className='flex flex-col gap-2'>
                        <label htmlFor="nama_lengkap">Nama Lengkap</label>
                        <input id="nama_lengkap" type="text" className='h-10 px-3 w-full bg-white' value={state?.nama_lengkap}
                            {...register('nama_lengkap', {
                                required: {
                                    value: true,
                                    message: "wajib di isi"
                                }
                            })}
                        />
                        {errors.nama_lengkap && <small className='text-xs italic text-red-500'>{errors.nama_lengkap.message}</small>}
                    </div>

                    <div className='flex flex-col gap-2'>
                        <label htmlFor="phone">Telp</label>
                        <input id="phone" type="text" className='h-10 px-3 w-full bg-white' value={state?.phone}
                            {...register('phone', {
                                required: {
                                    value: true,
                                    message: "wajib di isi"
                                }
                            })}
                        />
                        {errors.phone && <small className='text-xs italic text-red-500'>{errors.phone.message}</small>}
                    </div>

                    <div className='flex flex-col gap-2'>
                        <label htmlFor="email">Email</label>
                        <input id="email" type="text" className='h-10 px-3 w-full bg-white' value={state?.email}
                            {...register('email', {
                                required: {
                                    value: true,
                                    message: "wajib di isi"
                                }
                            })}
                        />
                        {errors.email && <small className='text-xs italic text-red-500'>{errors.email.message}</small>}
                    </div>

                    <div className='flex gap-2 justify-end w-full mt-6'>
                        <button type='submit' className='h-10 w-[120px] bg-green-600 text-white rounded-lg' >
                            Simpan
                        </button>
                    </div>


                </form>
            </div>
            <ButtonNavigation />
        </>
    )
}

export default ProfileAdd