import axios from "axios";

const profile_auth = async () => {
    try {
        const token = await localStorage.getItem("_dei_token")
        const result = await axios.get(process.env.REACT_APP_API_HOST + '/profile/auth', {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })

        return result
    } catch (error) {
        return error
    }
}

export default profile_auth