import axios from "axios"
import { api_host } from "../../constants/config"

export const user_login = async (nik, password) => {
    try {
        const result = await axios.post(api_host + "/user/login", {
            nik: nik,
            password: password
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        return result
    } catch (error) {
        return error
    }
}