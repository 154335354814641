import React from 'react'
import { AiOutlineHome, AiOutlineLogout, AiOutlinePaperClip, AiOutlineSetting, AiOutlineUser, AiOutlineFileDone } from "react-icons/ai"
import { Link, useNavigate } from "react-router-dom"

const ButtonNavigation = () => {

    const navigate = useNavigate()

    const handleLogout = () => {
        const cnf = window.confirm("Yakin Keluar Aplikasi ?")
        if (!cnf) {
            return
        }
        localStorage.clear()
        window.location.href = "/"
    }
    return (
        <div className='button_nav_container'>
            <Link to={'/'} className='button_nav_item'>
                <AiOutlineHome />
            </Link>

            <Link to={'/profiles'} className='button_nav_item'>
                <AiOutlineUser />
            </Link>

            <Link to={'/report-add'} className='button_nav_item_center'>
                <div className='center_icon'>
                    <AiOutlineFileDone />
                </div>
            </Link>

            <Link to={'/setting'} className='button_nav_item'>
                <AiOutlineSetting />
            </Link>

            <div onClick={handleLogout} className='button_nav_item'>
                <AiOutlineLogout />
            </div>

        </div>
    )
}

export default ButtonNavigation