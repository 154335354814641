import React from 'react'
import { AiOutlineDelete, AiOutlineEye, AiOutlineUpload } from 'react-icons/ai'

const ReportSkeleton = () => {
    return (
        <div
            className=" bg-gray-50 rounded-lg p-4 flex flex-col shadow-md overflow-hidden relative gap-2"
        >
            <div className="h-8 w-full bg-gray-300 rounded-full animate-pulse delay-75"></div>
            <div className="h-5 w-full bg-gray-200 rounded-full animate-pulse delay-100"></div>
            <div className="h-5 w-full bg-gray-200 rounded-full animate-pulse delay-150"></div>


            <div className="flex gap-2 mt-4">
                <button className="w-10 h-10 flex flex-col justify-center items-center bg-gray-200 text-white rounded-lg animate-pulse">
                    <AiOutlineEye />
                </button>

                <button className="w-10 h-10 flex flex-col justify-center items-center bg-gray-300 text-white rounded-lg animate-pulse">
                    <AiOutlineUpload />
                </button>

                <button className="w-10 h-10 flex flex-col justify-center items-center bg-gray-400 text-white rounded-lg animate-pulse">
                    <AiOutlineDelete />
                </button>
            </div>
        </div>
    )
}

export default ReportSkeleton