import React, { useState } from 'react'
import logo from "../assets/login_banner.png"
import { AiOutlineEye } from "react-icons/ai"
import { useForm } from "react-hook-form"
import { user_login } from '../api/user'

const Login = () => {

    const [state, setState] = useState({
        showPass: false,
        errorState: {
            nik: false,
            password: false
        }
    })

    // toggle password
    const togglePassword = () => {
        setState(prev => prev = { ...prev, showPass: !state.showPass })
    }

    // reset errorState
    const resetErrorState = () => {
        setState(prev => prev = { ...prev, errorState: { nik: false, password: false } })
    }


    // form
    const { register, watch, formState: { errors }, handleSubmit } = useForm()

    const handleLogin = (e) => {
        user_login(e.nik, e.password)
            .then(res => {
                if (res?.response?.status == 404) {
                    setState(prev => prev = {
                        ...prev, errorState: {
                            nik: true,
                            password: false
                        }
                    })
                    return
                }
                if (res?.response?.status == 401) {
                    setState(prev => prev = {
                        ...prev, errorState: {
                            nik: false,
                            password: true
                        }
                    })
                    return
                }
                localStorage.setItem("_dei_token", res?.data?.token)
                window.location.reload()
            })
            .catch(err => {
                console.info(err)
                alert("Terjadi Kesalahan")
            })
    }

    return (
        <div className='container_login'>
            <img src={logo} alt="logo" className='absolute mx-auto top-0' />
            <form className='login_form' onSubmit={handleSubmit(handleLogin)}>
                <div className='form_control'>
                    <label htmlFor="nik" className='form_label'>Nik</label>
                    <input type="tel" id='nik' className='form_input'
                        {...register("nik", {
                            required: {
                                value: true,
                                message: "Wajib diisi"
                            },
                            minLength: {
                                value: 6,
                                message: "Minimal 6 char"
                            }
                        })}
                        autoComplete="off"
                        onChange={resetErrorState}
                    // defaultValue="12312312312312"
                    />
                    {errors.nik && <small className='form_helper'>{errors.nik.message}</small>}
                    {state.errorState.nik && <small className='form_helper'>{"Nik Tidak Ditemukan"}</small>}
                </div>

                <div className='form_control'>
                    <label htmlFor="password" className='form_label'>Password</label>
                    <div className='form_input_icon'>
                        <button className='form_input_icon_item' type='button'
                            onClick={togglePassword}
                        >
                            <AiOutlineEye />
                        </button>
                        <input type={state.showPass ? "text" : "password"} id='password' className='form_input'
                            {...register("password", {
                                required: {
                                    value: true,
                                    message: "Wajib diisi"
                                },
                                minLength: {
                                    value: 6,
                                    message: "Minimal 6 char"
                                }
                            })}
                            autoComplete="off"
                            onChange={resetErrorState}
                        // defaultValue="fadliselaz!3"
                        />

                    </div>
                    {errors.password && <small className='form_helper'>{errors.password.message}</small>}
                    {state.errorState.password && <small className='form_helper'>{"Password Salah"}</small>}
                </div>
                <button className='button_login'>Login</button>
            </form>

            <small className='text-white mt-6'>Terkendala Login ? <b> Klik Disini </b></small>
        </div>
    )
}

export default Login