import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AiOutlineArrowUp, AiOutlineEdit, AiOutlineLoading, AiOutlineSearch } from 'react-icons/ai'
import { isError, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import profile_auth from '../../api/profile/auth'
import ButtonNavigation from '../../components/ButtonNavigation'
import Header from '../../components/Header'
import LoadingScreen from '../../components/LoadingScreen'
import { profile_update } from '../../api/profile'
import uploader_create from '../../api/uploader/create'


const Profiles = () => {
    const [state, setState] = useState({
        edit: false,
        isLoading: false
    })
    const { data, isLoading, refetch, isError } = useQuery({ queryKey: ['profile_auth'], queryFn: profile_auth })
    console.info(data)
    const memoProfile = useMemo(() => data?.data?.query, [data])
    const nameRef = useRef(null)

    const navigate = useNavigate()

    const handleEdit = () => {
        setState(prev => prev = {
            ...prev,
            edit: !state.edit
        })
    }

    const handleEditPhoto = (e) => {
        setState(prev => prev = { ...prev, isLoading: true })
        const reader = new FileReader()
        reader.onload = (x) => {
            uploader_create(x.target.result, 'profile')
                .then(res => {
                    profile_update({
                        photo: res.data.query.url
                    })
                        .then(res => {
                            setState(prev => prev = { ...prev, isLoading: false })
                            refetch()
                        })
                })
        }
        reader.readAsDataURL(e.target.files[0])
    }

    const handleSubmitUpdate = (e) => {
        e.preventDefault()
        profile_update({
            nama_lengkap: e.target.nama_lengkap.value,
            email: e.target.email.value,
            phone: e.target.phone.value,
        })
            .then(res => {
                refetch()
                setState(prev => prev = { ...prev, edit: false })
            })
    }

    useEffect(() => {
        if (state.edit) {
            nameRef.current.focus()
        }
    }, [state])

    if (data?.data?.query == null) {
        navigate('/profiles/add')
    }

    if (isLoading) {
        return <LoadingScreen />
    }

    return (
        <>
            <div className=''>

                <Header title={"Profiles"} />

                <form className='flex flex-col w-full gap-4 pt-12' onSubmit={handleSubmitUpdate}>

                    <div className='flex justify-center items-center flex-col relative w-[220px] mx-auto'>
                        <img src={memoProfile.photo} alt="" className='rounded-full h-[220px] w-[220px] object-cover' />

                        <label htmlFor="photo" className='h-12 w-12 bg-gradient-to-tr from-yellow-400 to-yellow-600 text-black text-lg rounded-full flex justify-center 
                        items-center absolute right-2 bottom-2'
                        >
                            {state.isLoading ? <AiOutlineLoading className='animate-spin' /> : <AiOutlineEdit />}
                        </label>
                        <input type="file" id='photo' accept="image/*" hidden onChange={handleEditPhoto} disabled={state.isLoading} />
                    </div>

                    <div className='flex flex-col gap-2'>
                        <label htmlFor="nama_lengkap">Nama Lengkap</label>
                        <input id="nama_lengkap" type="text" className={`h-10 px-3 w-full  
                        ${state.edit ? "bg-white" : "bg-inherit border-b-gray-300 font-bold border-b-[1px]"}`}
                            defaultValue={memoProfile.nama_lengkap} disabled={!state.edit} ref={nameRef} />
                    </div>

                    <div className='flex flex-col gap-2'>
                        <label htmlFor="phone">Telp</label>
                        <input id="phone" type="text" className={`h-10 px-3 w-full  
                        ${state.edit ? "bg-white" : "bg-inherit border-b-gray-300 font-bold border-b-[1px]"}`}
                            defaultValue={memoProfile.phone} disabled={!state.edit} />
                    </div>

                    <div className='flex flex-col gap-2'>
                        <label htmlFor="email">Email</label>
                        <input id="email" type="text"
                            className={`h-10 px-3 w-full  ${state.edit ? "bg-white" : "bg-inherit border-b-gray-300 font-bold border-b-[1px]"}`}
                            defaultValue={memoProfile.email} disabled={!state.edit} />
                    </div>

                    <div className='flex gap-2 justify-end w-full mt-6'>
                        {!state.edit ? (
                            <button type='button' className='h-10 w-[120px] bg-blue-500 text-white rounded-lg' onClick={handleEdit} disabled={state.isLoading}>
                                Edit
                            </button>
                        ) : (
                            <>
                                <button type='button' className='h-10 w-[120px] bg-gray-400 text-white rounded-lg' onClick={handleEdit}>
                                    Cancel
                                </button>

                                <button type='button' className='h-10 w-[120px] bg-green-600 text-white rounded-lg' onClick={handleEdit}>
                                    Save
                                </button>
                            </>
                        )}
                    </div>


                </form>
            </div>
            <ButtonNavigation />
        </>
    )
}

export default Profiles