import React from 'react'
import { jenis_kwh_meter } from "../../constants/dummyData"

const Page1 = ({ state, setState, handleChange, handleSelectedIdPel, }) => {

    const handleNext = () => {

        if (!state.jenis_kwh_meter || !state.nomer_ba || !state.idpel || !state.tanggal_ba) {
            return alert("Mohon lengkapi data !")
        }

        setState((prev) => (prev = { ...prev, page: state.page + 1 }));
    }

    return (
        <>
            <div className="form_group_p2tl">
                <label htmlFor="jenis_kwh_meter">Jenis KWH Meter</label>
                <div className="flex relative items-center">
                    <select
                        id="jenis_kwh_meter"
                        className="form_input_p2tl"
                        onChange={handleChange}
                        value={state?.jenis_kwh_meter}

                    >
                        {jenis_kwh_meter.map((e) => (
                            <option value={e?.name} key={e.id} >
                                {e.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="form_group_p2tl">
                <label htmlFor="idpel">ID PLN</label>
                <input
                    type="search"
                    className="form_input_p2tl"
                    id="idpel"
                    onChange={handleChange}
                    value={state?.idpel || ""}
                    disabled={
                        state?.jenis_kwh_meter == "PJU" ||
                        state?.jenis_kwh_meter == "Non Pelanggan"
                    }
                />
                <div className="flex flex-col w-full">
                    {state?.searchIdPel && state.searchIdPel.map((e) => {
                        return (
                            <div onClick={() => {
                                handleSelectedIdPel(e)
                            }} id={e.IDPEL} key={e.IDPEL} className={"h-14 w-full flex px-3 items-start bg-white border-b-2 border-gray-300 flex-col justify-center"}>
                                {e.IDPEL} <small className="text-[12px] text-gray-500">{e.NAMA}</small>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="form_group_p2tl">
                <label htmlFor="nomer_ba">Nomer BA</label>
                <input
                    type="tel"
                    className="form_input_p2tl"
                    id="nomer_ba"
                    onChange={handleChange}
                    value={state?.nomer_ba}
                />
            </div>

            <div className="form_group_p2tl">
                <label htmlFor="tanggal_ba">Tanggal BA</label>
                <input
                    type="date"
                    className="form_input_p2tl"
                    id="tanggal_ba"
                    onChange={handleChange}
                    value={state?.tanggal_ba}
                />
            </div>
            <div className="w-full flex justify-end">
                <button className="h-10 bg-blue-600 text-white w-[80px] ml-auto rounded-lg"
                    onClick={handleNext}
                >Next</button>
            </div>
        </>
    )
}

export default Page1