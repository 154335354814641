export const jenis_pelanggan = [
  {
    id: 1,
    name: "Pelanggan PLN",
  },
  {
    id: 2,
    name: "Non Pelanggan",
  },
];

export const jenis_kwh_meter = [
  {
    id: 1,
    name: "Pasca Bayar",
  },
  {
    id: 2,
    name: "Pra Bayar",
  },
  {
    id: 3,
    name: "PJU",
  },
  {
    id: 4,
    name: "Non Pelanggan",
  },
];

export const prediksi_temuan = [
  {
    id: 1,
    name: "Baik",
  },
  {
    id: 2,
    name: "Temuan",
  },
];

export const lokasi_simpan = [
  {
    id: 1,
    name: "Gudang PLN",
  },
  {
    id: 2,
    name: "Kantor DEI",
  },
];

export const golongan_pelanggaran = [
  {
    id: 1,
    name: "P1",
  },
  {
    id: 2,
    name: "P2",
  },
  {
    id: 3,
    name: "P3",
  },
  {
    id: 4,
    name: "K2",
  },
];

export const jenis_material = [
  {
    id: 1,
    name: "Meter",
  },
  {
    id: 2,
    name: "Kabel",
  },
  {
    id: 3,
    name: "MCB",
  },
];

export const status_temuan = [
  {
    id: 1,
    name: "Intra",
  },
  {
    id: 2,
    name: "Extra",
  },
];
