import React from 'react'
import { status_temuan } from "../../constants/dummyData"

const Page4 = ({ state, setState, handleChange, handleShowModalImage, handleFotoBa, handleFotoPemeriksaan, fotoBa, fotoPemeriksaan }) => {
    return (
        <>
            <div className="form_group_p2tl">
                <label htmlFor="prediksi_kwh">Prediksi KWH</label>
                <input
                    type="text"
                    className="form_input_p2tl"
                    id="prediksi_kwh"
                    onChange={handleChange}
                    value={state?.prediksi_kwh}
                />
            </div>

            <div className="form_group_p2tl">
                <label htmlFor="status_temuan">Status Temuan</label>
                <div className="flex relative items-center">
                    <select
                        id="status_temuan"
                        className="form_input_p2tl"
                        onChange={handleChange}
                        value={state?.status_temuan || "Extra"}
                    >
                        {status_temuan.map((e) => (
                            <option value={e.name} key={e.id}>
                                {e.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {state?.status_temuan == "Intra" && (
                <>
                    <div className="form_group_p2tl">
                        <label htmlFor="nominal_dp">Nominal DP</label>
                        <input
                            type="number"
                            className="form_input_p2tl"
                            id="nominal_dp"
                            onChange={handleChange}
                            value={state?.nominal_dp}
                        />
                    </div>
                </>
            )}

            <div className="form_group_p2tl">
                <label
                    htmlFor="file_ba"
                    className="h-12 w-full bg-blue-600 text-white rounded-lg flex justify-center items-center"
                >
                    Foto BA
                </label>
                <input
                    type="file"

                    className="hidden"
                    id="file_ba"
                    onChange={handleFotoBa}
                    accept="image/*"
                />
            </div>

            <div className="grid grid-cols-4 gap-4 w-full h-auto">
                {fotoBa.map((e, i) => {
                    return (
                        <div onClick={() => {
                            handleShowModalImage(e, i, "fotoBa")
                        }} key={i}>
                            <img src={e} alt="" className="w-[100%] h-20 object-cover" />
                        </div>
                    );
                })}
            </div>

            <div className="form_group_p2tl">
                <label
                    htmlFor="foto_pemeriksaan"
                    className="h-12 w-full bg-blue-600 text-white rounded-lg flex justify-center items-center"
                >
                    Foto Pemeriksaan
                </label>
                <input
                    type="file"

                    className="hidden"
                    id="foto_pemeriksaan"
                    onChange={handleFotoPemeriksaan}
                    accept="image/*"
                />
            </div>

            <div className="grid grid-cols-4 gap-4 w-full h-auto">
                {fotoPemeriksaan.map((e, i) => {
                    return (
                        <div onClick={() => {
                            handleShowModalImage(e, i, "fotoPemeriksaan")
                        }} key={i}>
                            <img src={e} alt="" className="w-[100%] h-20 object-cover" />
                        </div>
                    );
                })}
            </div>

            <div className="w-full flex justify-between gap-2">
                <button className="h-10 bg-gray-400 text-white w-[80px] rounded-lg"
                    onClick={() => {
                        setState((prev) => (prev = { ...prev, page: state.page - 1 }));
                    }}
                >Back</button>
                <button className="h-10 bg-blue-600 text-white w-[80px] rounded-lg"
                    onClick={() => {
                        setState((prev) => (prev = { ...prev, page: state.page + 1 }));
                    }}
                >Next</button>
            </div>

        </>
    )
}

export default Page4