import axios from "axios";

const profile_create = async (data) => {
    try {
        const token = await localStorage.getItem("_dei_token")
        const result = await axios.post(process.env.REACT_APP_API_HOST + `/profile/create`, data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            }
        })
        return result
    } catch (error) {
        return error
    }
}

export default profile_create