import axios from "axios";

const customer_search = async (idpel, signal) => {
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_CUSTOMER_HOST}/customer/search?value=` +
      idpel +
      "&limit=5",
      { signal: signal }
    );
    return result;
  } catch (error) {
    return error;
  }
};

export default customer_search;
