import React from 'react'
import { AiOutlineArrowUp, AiOutlineSearch } from 'react-icons/ai'
import ButtonNavigation from '../components/ButtonNavigation'
import Header from '../components/Header'


const Settings = () => {
    return (
        <>
            <div className=''>
                <Header title={"Settings"} />

            </div>
            <ButtonNavigation />
        </>
    )
}

export default Settings