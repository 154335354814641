import React from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Header = ({ title, back, next, nextMethod }) => {
  const navigate = useNavigate();
  const handleBackward = () => {
    navigate(-1);
  };
  return (
    <div
      className="w-screen h-12 px-4 bg-white flex items-center fixed
        top-0 left-0 shadow-lg text-gray-500 z-[200]"
    >
      {back && (
        <button
          className="h-12 w-12 flex justify-center items-center -ml-4"
          onClick={handleBackward}
        >
          <AiOutlineArrowLeft />
        </button>
      )}
      <h1 className="capitalize ">{title}</h1>
      {next && (
        <button
          className="h-8 bg-green-600 text-white ml-auto px-3 rounded-lg"
          onClick={nextMethod}
        >
          Simpan
        </button>
      )}
    </div>
  );
};

export default Header;
