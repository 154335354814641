import axios from "axios";

const profile_update = async (data) => {
    try {
        const token = await localStorage.getItem('_dei_token')
        const result = await axios.put(process.env.REACT_APP_API_HOST + `/profile/update`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        return result
    } catch (error) {
        return error
    }
}

export default profile_update