

export const delete_temp_p2tl = async (idpel) => {
    try {
        let defaultData = await JSON.parse(localStorage.getItem('dei_temp_data'))
        let deleteDefaultData = await defaultData.filter((e) => {
            return e.idpel !== idpel
        })
        let storeBack = await localStorage.setItem('dei_temp_data', JSON.stringify(deleteDefaultData))

    } catch (error) {
        return error
    }
}