import axios from "axios";

export const p2tl_read = async () => {
  try {
    const arr = await localStorage.getItem("dei_temp_data");
    if (arr == undefined) {
      return false;
    }
    let result = await JSON.parse(arr);
    return {
      data: {
        query: result.reverse()
      }
    }
  } catch (error) {
    return error;
  }
};

export const p2tl_find_by_nik = async (page = 1, limit = 10) => {
  try {
    const token = await localStorage.getItem('_dei_token')
    const result = await axios.post(process.env.REACT_APP_API_HOST + `/dhp/find_by_nik?page=${1}&limit=${10}`, {}, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    })
    return result
  } catch (error) {
    return error
  }
}
