import React, { useMemo } from "react";
import { useState } from "react";
import { AiOutlineDelete, AiOutlineEye, AiOutlineSearch, AiOutlineUpload } from "react-icons/ai";
import ButtonNavigation from "../components/ButtonNavigation";
import Header from "../components/Header";
import { useQuery } from "react-query";
import { p2tl_find_by_nik, p2tl_read } from "../api/p2tl";
import { useEffect } from "react";
import LoadingScreen from "../components/LoadingScreen";
import { ReportSkeleton } from "../components/skeleton";
import { create_p2tl, delete_temp_p2tl } from "../api/p2tl";

const Home = () => {
  const [state, setState] = useState({
    mappingSkeleton: [0, 1, 2],
    draft: true
  });

  const memoState = useMemo(() => state, [state])

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["local_data"],
    queryFn: memoState?.draft ? p2tl_read : p2tl_find_by_nik,
  });

  // if (isLoading) {
  //   return <LoadingScreen />;
  // }

  const handleDeleteTemp = (idpel) => {
    const cnf = window.confirm("Yakin delete data ?")
    if (!cnf) {
      return
    }
    delete_temp_p2tl(idpel)
      .then(res => {
        refetch()
      })
      .catch(err => {
        console.error(err)
      })
  }

  const toggleDraft = async (value) => {
    const ss = await setState(prev => prev = { ...prev, draft: value })
    const rf = await refetch()
  }

  const handleUpload = (idpel) => {
    const cnf = window.confirm("Pastikan Internet Stabil \nUntuk melakukan upload")
    if (!cnf) {
      return
    }
    create_p2tl(idpel)
      .then(res => {
        refetch()
      })
  }

  return (
    <>

      <div className="">
        <span className="flex relative items-center">
          <input
            type="text"
            className="search_input"
            placeholder="Cari Data P2TL"
          />
          <button className="absolute w-12 h-12 flex justify-center items-center right-0">
            <AiOutlineSearch className="text-lg" />
          </button>
        </span>

        <div className="w-full h-12 flex mt-2">
          <button className={`flex-1 flex justify-center items-center rounded-lg ${state.draft && "bg-gradient-to-b from-gray-700 to-gray-500 text-white"}`}
            onClick={() => { toggleDraft(true) }}
          >
            Draft
          </button>
          <button className={`flex-1 flex justify-center items-center rounded-lg ${!state.draft && "bg-gradient-to-b from-blue-700 to-blue-500 text-white"}`}
            onClick={() => { toggleDraft(false) }}
          >
            Terupload
          </button>
        </div>

        <div className="flex flex-col gap-4 mt-4 justify-center mb-16 pb-32">
          {isLoading && (
            <>
              {state.mappingSkeleton.map((e) => (
                <ReportSkeleton key={e} />
              ))}
            </>
          )}

          {data &&
            data?.data?.query?.map((e, i) => (
              <div
                key={i}
                className=" bg-white rounded-lg p-4 flex flex-col shadow-md overflow-hidden relative"
              >
                <h1 className="font-bold">{e.nama}</h1>
                <small className="font-light">{e.idpel}</small>
                <small className="text-gray-400 italic mt-auto">{e.tanggal_ba}</small>
                <div className={`absolute h-8 w-[300px]  text-white flex justify-center items-center rotate-45 -right-28 top-4 shadow-md 
                  bg-gradient-to-br ${e.prediksi_temuan == "Baik" ? "bg-green-500 from-green-700 to-green-500" : "bg-green-500 from-red-700 to-red-500"} text-sm`}>
                  {e.prediksi_temuan}
                </div>

                {state.draft && (
                  <div className="flex gap-2 mt-4">
                    <button className="w-10 h-10 flex flex-col justify-center items-center bg-yellow-400 text-white rounded-lg" >
                      <AiOutlineEye />
                    </button>

                    <button className="w-10 h-10 flex flex-col justify-center items-center bg-green-600 text-white rounded-lg" onClick={() => { handleUpload(e.idpel) }}>
                      <AiOutlineUpload />
                    </button>

                    <button className="w-10 h-10 flex flex-col justify-center items-center bg-red-600 text-white rounded-lg" onClick={() => { handleDeleteTemp(e.idpel) }}>
                      <AiOutlineDelete />
                    </button>
                  </div>
                )}

              </div>
            ))}

        </div>
      </div>
      <ButtonNavigation />
    </>
  );
};

export default Home;
