import axios from "axios";

const uploader_create = async (base64, filename) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_HOST + "/uploader/create", {
            filename: filename,
            base64: base64
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        return result
    } catch (error) {
        return error
    }
}


export default uploader_create