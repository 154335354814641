import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom"
import Login from './screens/Login';
import { useQuery } from "react-query"
import { user_auth } from './api/user';
import LoadingScreen from './components/LoadingScreen';
import Dashboard from './screens/Dashboard';
import { ProfileAdd, Profiles } from './screens/profile';
import ReportAdd from './screens/ReportAdd';
import Home from './screens/Home';
import Settings from './screens/Settings';

function App() {

  const { data, isLoading, isError, refetch } = useQuery({ queryKey: "auth", queryFn: user_auth }, {
    staleTime: 60000
  })


  if (isLoading) {
    return <LoadingScreen />
  }


  if (data?.status === 200) {
    return (
      <Routes>
        <Route path='/' element={<Dashboard />} >
          <Route index element={<Home />} />
          <Route path='/profiles' element={<Profiles />} />
          <Route path='/profiles/add' element={<ProfileAdd />} />
          <Route path='/report-add' element={<ReportAdd />} />
          <Route path='/setting' element={<Settings />} />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path='/' element={<Login />} />
    </Routes>
  );
}

export default App;
