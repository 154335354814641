import React from 'react'

const Page2 = ({ state, setState, handleChange, }) => {
    return (
        <>
            <div className="form_group_p2tl">
                <label htmlFor="nomer_ktp">No KTP</label>
                <input
                    type="text"
                    className="form_input_p2tl"
                    id="nomer_ktp"
                    onChange={handleChange}
                    value={state?.nomer_ktp}
                />
            </div>

            <div className="form_group_p2tl">
                <label htmlFor="nama">Nama</label>
                <input
                    type="text"
                    className="form_input_p2tl"
                    id="nama"
                    onChange={handleChange}
                    value={state?.nama}
                />
            </div>

            <div className="form_group_p2tl">
                <label htmlFor="tarif">Tarif</label>
                <input
                    type="text"
                    className="form_input_p2tl"
                    id="tarif"
                    onChange={handleChange}
                    value={state?.tarif}
                />
            </div>

            <div className="form_group_p2tl">
                <label htmlFor="daya">Daya</label>
                <input
                    type="text"
                    className="form_input_p2tl"
                    id="daya"
                    onChange={handleChange}
                    value={state?.daya}
                />
            </div>

            <div className="form_group_p2tl">
                <label htmlFor="alamat">Alamat</label>
                <textarea
                    className="form_textarea_p2tl"
                    id="alamat"
                    onChange={handleChange}
                    value={state?.alamat || ""}
                ></textarea>
            </div>

            <div className="w-full flex justify-between gap-2">
                <button className="h-10 bg-gray-400 text-white w-[80px] rounded-lg"
                    onClick={() => {
                        setState((prev) => (prev = { ...prev, page: state.page - 1 }));
                    }}
                >Back</button>
                <button className="h-10 bg-blue-600 text-white w-[80px] rounded-lg"
                    onClick={() => {
                        setState((prev) => (prev = { ...prev, page: state.page + 1 }));
                    }}
                >Next</button>
            </div>
        </>
    )
}

export default Page2