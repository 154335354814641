import React from "react";
import logo from "../assets/login_banner.png";

const LoadingScreen = () => {
  return (
    <div className="container bg-gradient-to-t from-blue-900 to-blue-600 flex flex-col justify-center items-center fixed top-0 left-0 z-[2000]">
      <img src={logo} alt="" className="h-[60px] object-cover animate-bounce" />
      <h1 className="text-white">Loading</h1>
    </div>
  );
};

export default LoadingScreen;
